<template>
  <window-content>
    <div class="wrapper-sm">
      <e-row mr>

        <e-col style="min-width: 45%;">
          <date-input label="Data do Pagamento (De)" v-model="filters.data1"/>
        </e-col>

        <e-col style="min-width: 45%;">
          <date-input label="Data do Pagamento (Até)" v-model="filters.data2"/>
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="ID do Leilão"
          >
            <erp-input v-model="filters.id" />
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Código"
          >
            <erp-input v-model="filters.codigo" />
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 98%">
          <comitente-select multiple :columns="[
                      {label: 'Nome', value: 'label'},
                      {label: 'Tipo', value: 'tipoName'}
                    ]" v-model="filters.comitentes" />
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Arrematante"
          >
            <arrematante-input v-model="filters.arrematante" disable-new />
          </erp-s-field>
        </e-col>
        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Filtrar por"
          >
            <div class="flex">
              <label class="flex items-center m-b-xs m-r-sm">
                <u-radio v-model="filters.tipo" val="todos" class="m-r-xs"/>
                Todos
              </label>
              <label class="flex items-center m-b-xs m-r-sm">
                <u-radio v-model="filters.tipo" val="ativos" class="m-r-xs"/>
                Somente Confirmados
              </label>
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio v-model="filters.tipo" val="cancelados" class="m-r-xs"/>
                Cancelados / Estornados
              </label>
            </div>
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn @click="gerar(formato)" :loading="loading" label="Gerar" icon="print" icon-type="fa" icon-style="light" flat no-caps/>
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowFooter,
  ErpSField,
  ErpInput,
  /*ErpSelect*/
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import mixinPrint from "@/components/relatorios/components/mixinPrint"
import {relatorio} from "@/domain/relatorios/services"
import createPrint from "@/components/relatorios/components/customPrint/createPrint"
import ComitenteSelect from "@/components/comitente/helpers/input/ComitenteSelect"
import ArrematanteInput from "@/components/arrematante/helpers/input/ArrematanteInput"
import DateInput from "@/reuse/input/Date"
import {datePtToEn} from "@/utils/date"
import {date, URadio} from "uloc-vue"

let filters = {
  id: null,
  codigo: null,
  data1: null,
  data2: null,
  comitentes: null,
  tipo: 'ativos',
  arrematante: null
}

export default {
  name: 'AnalisePagamentoLeilaoWindow',
  mixins: [mixinPrint],
  data() {
    return {
      formato: 'html',
      filters: JSON.parse(JSON.stringify(filters))
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    createPrint: createPrint,
    actionPrint (action) {
      console.log('Print Action ', action)
      this.gerar(action)
    },
    gerar(formato = 'html') {
      let filters = JSON.parse(JSON.stringify(this.filters))

      if (!filters.id && !filters.codigo && !filters.data1 && !filters.data2 && !filters.arrematante) {
        this.$uloc.dialog({
          className: 'erp-confirm c-error',
          title: 'Preenchimento inválido',
          color: 'info',
          message:'Preencha o ID, código do leilão, arrematante ou um intervalo de datas'
        })
        return
      }

      let data1, data2
      if (filters.data1) {
        data1 = datePtToEn(filters.data1)
        data2 = datePtToEn(filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        filters.data1 = data1
        filters.data2 = data2
      }

      this.loading = true
      relatorio('analisePagamentoLeilao', filters, formato)
          .then(response => {
            this.loading = false
            formato === 'html' && this.createPrint(response.data, () => import('@/components/relatorios/components/customPrint/DefaultPrint'), 'gerencial', 'Relação de Pagamentos Feitos no Leilão', 'landscape')
          })
          .catch(error => {
            this.loading = false
            if (formato === 'html') {
              this.alertApiError(error)
            } else{
              error.data.text().then(text => {
                this.alertApiError({data: JSON.parse(text)})
              })
            }
          })
    }
  },
  components: {
    ECol,
    ERow,
    WindowFooter,
    WindowContent,
    ErpSField,
    ErpInput,
    ComitenteSelect,
    ArrematanteInput,
    DateInput,
    URadio
  }
}
</script>
